@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
html {
    background-color: #fff !important;
}
#PERFIL {
    padding: 35px 0;
    margin-left: 8%;
    margin-right: 8%;
    font-family: "Mukta", sans-serif !important;
}

#PERFIL .card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
}
#PERFIL .card .card-header {
    background-color: transparent;
    border: 0 solid;
    font-weight: bold;
    font-size: 25px;
    padding: 25px 15px 0 15px;
}
#PERFIL .card .card-header .card-title {
    border-bottom: 4px solid #dcdcdc;
}
#PERFIL .card .card-header .icon {
    height: 55px;
    width: 50px;
    margin-bottom: 15px;
}
#PERFIL .card .card-body {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
}
#PERFIL .perfil-tab {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #282828;
    cursor: pointer;
    padding: 10px 15px;
    margin: 0;
}
#PERFIL .perfil-tab:hover {
    background: #ffbe0b 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font-weight: bold;
}

#PERFIL .container-tabs {
    background-color: transparent;
}
#PERFIL .container-tabs .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    border: 0 solid;
    margin-bottom: 5px;
}
#PERFIL .container-tabs .react-tabs__tab {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #282828;
    cursor: pointer;
    padding: 10px 15px;
    margin: 0;
    border: 0 solid;
}
#PERFIL .container-tabs .react-tabs__tab--selected {
    background: #ffbe0b 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font-weight: bold;
}
#PERFIL .titulo-info {
    font-weight: bold;
    letter-spacing: 0px;
    color: #282828;
}
#PERFIL .txt-info {
    font-size: 15px;
    letter-spacing: 0px;
    color: #282828;
}
#PERFIL .btn-editar {
    background: #3a86ff 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 5px 15px !important;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
}
#PERFIL .btn-primary {
    padding: 5px 15px !important;
    height: auto;
}
.modal-perfil-title {
    background-color: transparent;
    border: 0 solid;
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 5px;
    border-bottom: 4px solid #dcdcdc;
    margin-bottom: 15px;
}

.body-modal-perfil label {
    font-size: 18px;
    color: #282828;
}

.body-modal-perfil input {
    background-color: #fff;
    border: 2px solid #aaa8a8;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
}
.body-modal-perfil input:focus {
    border: 2px solid #ffbe0b;
}
/* d-flex flex-lg-row flex-column mb-2 mb-md-0 mb-lg-0 */
#PERFIL .container-btn-datos-personales {
    display: flex;
    flex-direction: row;
}
@media only screen and (max-width: 1200px) and (min-width: 1025px) {
}
@media only screen and (max-width: 1024px) and (min-width: 992px) {
    #PERFIL {
        margin-left: 2%;
        margin-right: 2%;
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
    #PERFIL {
        margin-left: 0;
        margin-right: 0;
    }
    #PERFIL .btn-editar {
        width: 100%;
    }
    #PERFIL .btn-primary {
        width: 100%;
    }
    #PERFIL .container-btn-datos-personales {
        flex-direction: column;
    }
}

@media only screen and (max-width: 767px) and (min-width: 425px) {
    #PERFIL {
        margin-right: 0px;
        margin-left: 0px;
    }
    #PERFIL .container-btn-datos-personales {
        margin-bottom: 20px;
        flex-direction: column;
        width: 100%;
    }
}
@media only screen and (max-width: 424px) and (min-width: 0px) {
    #PERFIL {
        margin-right: 0px;
        margin-left: 0px;
    }
    #PERFIL .container-btn-datos-personales {
        margin-bottom: 20px;
        flex-direction: column;
        width: 100%;
    }
}
