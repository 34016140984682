@media only screen and (max-width: 768px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.react-bs-table-bordered{
  border: none !important;
  border-radius: 0 !important;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}
.swal2-popup .swal2-styled{
    padding: .625em 1em;
    margin-bottom: 15px !important;
}
.react-bs-table-tool-bar{
    margin-bottom: 0;
}
