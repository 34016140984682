@import "../../node_modules/react-day-picker/lib/style.css";
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "../../node_modules/react-dropdown-advanced/styles/rdropdown.css";
@import "../../node_modules/react-select/dist/react-select.css";


@import "configs/color";
@import "_custom_styles_btn";
@import "bootstrap_reset.css";
@import "_custom_global_styles";
@import "_utils";
@import "_custom_styles_controls";
@import "_scrollbar.css";
@import "_custom_factura";
@import "_custom_tab";
@import "_custom_editor_wysiwyg";
/*NODE MODULES CSS*/


@import "impresion";
html {
    font-size: 1rem;
}
.container {
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 16px;
    margin-left: 60px;
    margin-right: 60px;
}
.container__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}
/*Style preloader*/

.loader-container {
    min-height: 140px;
}

.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 60px;
    height: 60px;
    left: 50%;
    background-color: #e0e0e0;
    position: absolute;
    margin: 1rem 1rem 1rem -30px;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
}

.small-loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    position: absolute;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
    margin: 1rem 1rem 1rem -30px;
    left: 40%;
}

/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .topnavbar {
        min-height: 43px;
        padding: 0.2rem 0.5rem 0 0.5rem !important;
    }
    .content-wrapper {
        top: 44px;
    }
}
@media only screen and (max-width: 767px) {
    .container {
        margin-left: 0;
        margin-right: 0;
    }
    .sidebar {
        display: none !important;
    }
    .content-wrapper {
        left: 0 !important;
        width: 100%;
        padding: 20px 0 0;
        top: 53px;
    }
}

.step-number {
    background: #dee2e6;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-size: 20px;
    font-weight: bold;
}
.step-number.accomplished {
    background: #32a3ff;
    color: white;
}

.navbar {
    color: $negro;
    /* background-image: linear-gradient(to right, var(--orange1), var(--orange2)); */
    background-color: $yellow;
    font-weight: 800;
    text-transform: uppercase;
}

.grid-titulo {
    font-size: 1.3rem;
    font-weight: bold;
    border-bottom: 2px solid $gris-dark;
    color: $negro;
}

.grid-titulo-sm {
    font-size: 0.9rem;
    border-bottom: 2px solid $gris-dark;
    color: $negro;
}

.form-container {
    background-color: white;
    width: 70%;
    margin: auto;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #BFBFBF;
    font-weight: bold;
}

.form-container label {
    font-weight: bold;
}

.label-view {
    color: #606060;
    font-weight: bold;
}

.input-view {
    width: 100%;
    border-bottom: 3px solid #E8E8E8;
    padding: 0 15px 5px 15px;
    font-weight: normal;
    word-break: break-word;
}

.img-icono-cantidades {
    width: 12px;
    height: 12px;
}

div b {
    font-weight: bold;
}

.btn-form {
    color: #006EAA;
}

table th {
    background-color: rgba(0, 0, 0, 0.075);
    font-size: 1rem;
    border-right: 1px solid rgba(0, 0, 0, 0.075)
}

table td {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.separador {
    font-size: 1.3rem;
    font-weight: bold;
    border-right: 3px solid #4D4D4D;
}

@media only screen and (max-width: 768px) {
    .form-container {
        width: 80%;
        margin: auto;
        padding: 15px;
    }
}

@media only screen and (max-width: 425px) {
    .form-container {
        width: 92%;
    }
    .input-view {
        padding-left: 0;
        padding-right: 0;
    }
}

.container-tienda-img{
    width: 100%;
}
