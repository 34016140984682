@import "../../../../style/configs/color.css";

.grid-container-white {
    border-radius: 10px;
    border: 2px solid #E8EBEE;
    margin-top: 1em;
    background-color: white;
}
#estado-compra .container-info {
    font-family: 'Mukta';
    margin: 50px 15% 0 15%;
}

#estado-compra .container-info .grid-titulo {
    font-family: 'Mukta';
    font-size: 25px;
    font-weight: 7000;
    letter-spacing: 0px;
    color: #282828;
    border-bottom: 3px solid #DCDCDC;
}
#estado-compra .container-info .grid-titulo-sm {
    color: #AAA8A8;
    font-size: 20px;
}

#estado-compra .container-info .form-group label {
    font-weight: bold;
    font-size: 18px;
}
#estado-compra .container-info .form-group .label-view {
    color: #030101;
    font-size: 15px;
}
#estado-compra .container-info .form-group .input-view {
    font-size: 15px;
    border: 0;
    font-weight: 400;
}
#estado-compra .container-info .table-detalle-orden-online thead tr {
    border: 1px solid #A3A3A3;
}
#estado-compra .container-info .table-detalle-orden-online thead th:first-child {
    width: 70%;
}
#estado-compra .container-info .table-detalle-orden-online thead th:last-child {
    width: 30%;
}
#estado-compra .container-info .table-detalle-orden-online thead th {
    background-color: #DCDCDC;
    padding-left: 30px;
    font-size: 20px;
    border: 0 solid #DCDCDC;
}
#estado-compra .container-info .table-detalle-orden-online tbody tr {
    border: 1px solid #A3A3A3;
    color: #707070;
}
#estado-compra .container-info .table-detalle-orden-online td {
    border: 0;
    padding-left: 30px;
    font-size: 18px;
}
#estado-compra .container-info .table-detalle-orden-online td:first-child {
    font-weight: 400;
}
#estado-compra .container-info .table-detalle-orden-online td:last-child {
    font-weight: 700;
}
#estado-compra .container-info .table-detalle-orden-online .row-total {
    background-color: #3A86FF;
    color: #FFFFFF;
}
#estado-compra .container-info .table-detalle-orden-online .row-total td{
    font-weight: 700 !important;
}

#estado-compra .text-pending {
    color: #FB5607;
}
#estado-compra .text-finish {
    color: #00A200;
}
#estado-compra .form-container {
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 15px;
}

.list-notas {
    list-style-position: outside;
    padding-left: 0;
    margin: 0;
}
.list-notas li {
    color           : #282828;
    padding         : 10px;
    background-color: #f2f2f2;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    margin-bottom   : 10px;
    border-radius   : 5px;
    font-size       : 15px;
    font-weight     : 400;
}
.list-notas li:last-child {
    margin-bottom: 0;
}
.list-notas li small {
    width: 100%;
    text-align: right;
}
.notes-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #707070;
    font-weight: 600;
    margin-bottom: 7px;
}
@media only screen and (max-width: 1024px) {
    #estado-compra .container-info {
        margin-left: 8%;
        margin-right: 8%;
    }
}

@media only screen and (max-width: 800px) {
    .grid-container-white {
        margin-top : 1em;
        border     : 2px transparent;
        text-align : left;
        align-items: left;
    }
}

@media only screen and (max-width: 425px) {
    #estado-compra .container-info {
        margin-left: 0;
        margin-right: 0;
    }
    #estado-compra .container-info .grid-titulo {
        font-family: 'Mukta';
        font-size: 20px;
    }
    #estado-compra .container-info .table-detalle-orden-online thead th:first-child {
        width: 50%;
    }
    #estado-compra .container-info .table-detalle-orden-online thead th:last-child {
        width: 50%;
    }
    #estado-compra .container-info .table-detalle-orden-online thead th {
        font-size: 18px;
    }
    #estado-compra .container-info.table-detalle-orden-online td {
        padding-left: 15px;
        font-size: 15px;
    }
}
