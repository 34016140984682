@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

.bm-burger-button {
    display: none;
}

.bm-menu-wrap {
    display: none;
}

.bm-overlay {
    display: none;
}

.no-content-sidebar {
    display: none;
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
    .bm-menu-wrap {
        display: block;
    }
    .bm-burger-butto-home {
        display : block;
        position: fixed;
        width   : 30px;
        height  : 30px;
        right   : 31px;
        top     : 24px !important;
    }

    * Individual item */ .bm-item {
        display: inline-block;

        /* Our sidebar item styling */
        text-decoration: none;
        margin-bottom  : 10px;
        color          : #282828;
        transition     : color 0.2s;
        outline-color  : transparent !important;
    }

    h1 {
        outline-color: transparent !important;
    }

    /* Change color on hover */
    .bm-item:hover {
        color: #282828;
    }

    /* The rest copied directly from react-burger-menu docs */

    /* Position and sizing of burger button */
    .bm-burger-button {
        display : block;
        position: fixed;
        width   : 30px;
        height  : 30px;
        right   : 31px;
        top     : 81px;
    }

    .bm-burger-button div img {
        width: 30px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #282828;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height     : 33px !important;
        width      : 85px !important;
        display    : flex;
        align-items: center;
    }

    .bm-cross-button::before {
        content    : "Cerrar";
        font-family: 'Mukta', sans-serif;
        color      : #282828;
        font-weight: 900 !important;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #282828;
        height    : 22px !important;
    }

    /* General sidebar styles */
    .bm-menu {
        background: #FFFFFF;
        padding   : 2.5em 15px 0;
        font-size : 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        height: fit-content !important;
        color : #292929;
    }

    /* Styling of overlay */
    .bm-overlay {
        display   : block;
        background: rgba(0, 0, 0, 0.3);
    }

    .bm-menu .filtros {
        margin-top   : 25px;
        padding-right: 15px;
        outline-color: transparent !important;
    }

    .bm-menu .txt-filtro {
        width        : 106%;
        border-radius: 5px;
    }

    .bm-menu .slider-text {
        font-size: 20px !important;
    }

    .bm-menu .tree-title {
        border-radius: 5px;
        font-size    : 20px !important;
    }

    /* .bm-menu .rc-slider-rail, .bm-menu .rc-slider-step {
        width: 95% !important;
    } */
    /* .bm-menu .rc-slider-handle-2 {
        left: 95% !important;
    } */

    .filtros-aplicados {
        position        : absolute;
        width           : 15px;
        height          : 15px;
        border-radius   : 50%;
        background-color: #FF006E;
        top             : -7.5px;
        right           : -7.5px;
    }

    .bm-menu {
        ol.tree {
            border-radius: 5px;
            border: 1px solid #AAA8A8;
        }
        li.parent label {
            display: none;
        }
        li.parent label::after {
            content: none;
        }
        li.parent input[type=checkbox]:checked+label::after {
            content: none;
        }
        .titulo.active::after {
            position       : absolute;
            top            : 0;
            right          : 5px;
            display        : flex;
            align-items    : center;
            justify-content: center;
            content        : "\f111";
            font-family    : "FontAwesome";
            padding        : 10px 5px;
            line-height    : 16px;
            color          : #FB5607;
            font-size      : 12px;
        }
    }
}