@import "./configs/color.css";

.form-control {
    border-radius: 1em;
    border: 0.1em solid $input;
    font-size: 0.8rem;
}
.Select {
    height: 33.33px !important;
}

.form-control:focus {
    border: 0.1em solid $naranja;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.form-control:active {
    border: 0.1em solid $naranja;
}

.form-group label {
    margin-top: 0px;
    font-weight: bold;
}

.label-radio {
    padding: 0 10px;
}

/* DATEPICKER VENDEMAS */
.date-picker-vendemas {
    border-radius: 1em;
    input {
        border-radius: 1em;
        border: 0.07em solid $input;
        height: 33px;
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        outline-color: transparent !important;
    }
    input:focus {
        border: 0.1em solid $naranja;
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    input:active {
        border: 0.1em solid $naranja;
    }
}

.date-picker-vendemas-overlay {
    position: absolute;
    background-color: #FFF;
    z-index: 1;
    border-radius: 0.5em;
    border: 0.1em solid $input;
}
.DayPicker-Caption > div {
    text-transform: capitalize;
}
.DayPicker-Day {
    padding: 0.2em;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $azul;
}
