@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
.load-mask {
    position: relative;
}

.load-mask-content.blur {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    backface-visibility: hidden;
    filter: blur(0);
    transition: filter 0.2s ease-out;
}

.load-mask-content.blur.loading {
    filter: blur(5px);
}

.loader-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-container.radius {
    border-radius: 10px;
}

.loader-container.dark {
    background: #2828284a
}

.loader-container.light {
    background: rgba(255, 255, 255, 0.5)
}

.txt-loading {
    font-family   : 'Mukta', sans-serif;
    font-weight: 400;
    color: #282828;
    font-size: 1.5rem !important;
    margin: 0;
}