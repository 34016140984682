@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
.register-bg {
    background-color: #f5f5f5;
    height: 100%;
    padding: 34px 0;
    color: #282828 !important;
}
/* CARD-LOGIN */
.card-register {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 15px !important;
    font-family: "Mukta", sans-serif !important;
    width: 45% !important;
}
.card-register .card-header {
    background-color: transparent;
    border: 0 solid;
    font-weight: bold;
    font-size: 25px;
    padding: 25px 10% 0 10%;
}
.card-register .card-header .card-title {
    border-bottom: 4px solid #dcdcdc;
}
.card-register .card-header .icon-register {
    height: 55px;
    width: 50px;
    margin-bottom: 15px;
}
.card-register .card-body {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5px;
}

.card-register .card-body .txt-register {
    font-size: 20px;
    font-weight: bold;
}

.card-register label {
    font-size: 18px;
    color: #282828;
}
.card-register input {
    background-color: #fff;
    border: 2px solid #aaa8a8;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
}
.card-register input:focus {
    border: 2px solid #ffbe0b;
}
.card-register .txt-info {
    font-size: 16px;
    font-weight: normal;
}

.btn-register {
    background-color: #ffbe0b !important;
    border-radius: 5px !important;
    padding: 5px 15px !important;
    height: 35px;
    width: 50%;
    color: #282828 !important;
    font-size: 16px !important;
}
.btn-register:hover {
    opacity: 0.9;
}

.txt-ingresa {
    color: #3a86ff;
    font-size: 16px;
    font-weight: bold;
    text-align: center !important;
}

.btn-facebook {
    background-color: #3a589e;
    height: 35px;
    color: #fff;
    border: 0;
    font-family: "Mukta", sans-serif !important;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px 15px;
    margin-right: 10px;
}
.btn-facebook:hover {
    opacity: 0.9;
}
.btn-facebook i {
    margin-right: 0.5em;
}

.btn-google {
    background-color: #da4e30 !important;
    height: 35px;
    color: #fff !important;
    border: 0 !important;
    font-family: "Mukta", sans-serif !important;
    font-size: 18px !important;
    font-weight: bold !important;
    border-radius: 5px !important;
    padding: 5px 15px !important;
    outline: 0 !important;
    margin-left: 10px;
}
.btn-google div {
    display: none !important;
    background-color: #da4e30 !important;
}
.btn-google i {
    margin-right: 0.5em;
}

/* MEDIUM  DESKTOP */
@media only screen and (max-width: 1440px) and (min-width: 1025px) {
    .card-register {
        width: 50% !important;
    }
}

/* SMALL  DESKTOP */
@media only screen and (max-width: 1024px) and (min-width: 992px) {
    .card-register {
        width: 75% !important;
    }
}

/* TABLET */
@media only screen and (max-width: 991px) and (min-width: 768px) {
    .card-register {
        width: 100% !important;
    }
}

/* LARGE MOBILE */
@media only screen and (max-width: 767px) and (min-width: 425px) {
    .card-register {
        width: 100% !important;
    }
    .container-btn-redes {
        flex-direction: column;
    }
    .container-btn-redes .btn-facebook,
    .container-btn-redes .btn-google {
        margin: 7.5px 0;
        /* width: 85%; */
    }
}
/* MEDIUM AND SMALL MOBILE */
@media only screen and (max-width: 424px) and (min-width: 0px) {
    .card-register {
        width: 100% !important;
    }
    .container-btn-redes {
        flex-direction: column;
    }
    .container-btn-redes .btn-facebook,
    .container-btn-redes .btn-google {
        margin: 7.5px 0;
        /* width: 85%; */
    }
}
