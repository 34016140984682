@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
#NAVBAR-TO {
    z-index: 1;
    transition: 0.2s ease-out;
}
.container-navbar {
    margin-left: 8%;
    margin-right: 8%;
}
.container-brand #CARRITO-MOVIL {
    display: none;
}
.navbar-rigth {
    font-family: "Mukta", sans-serif !important;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.navbar-rigth .link-tiendas {
    margin-top: 15px;
    text-decoration: none;
    margin-right: 25px;
    color: #282828;
}
.navbar-rigth .link-tiendas span {
    font-weight: normal;
    letter-spacing: 0px;
    color: #484848;
    text-transform: uppercase;
}
/* .navbar-rigth .link-tiendas span:hover {
    font-weight: 600;
} */
.navbar-rigth .link-tiendas img {
    margin-bottom: 13px;
    margin-left: 10px;
    width: 35px;
    height: 22px;
}
.navbar-rigth .link-mi-cuenta {
    text-decoration: none;
    color: #282828;
}
.navbar-rigth .link-mi-cuenta.img {
    margin-top: 15px;
}
.navbar-rigth .link-mi-cuenta span {
    font-weight: normal;
    letter-spacing: 0px;
    color: #484848;
    text-transform: uppercase;
    margin-right: 10px;
    margin-bottom: 5px;
}
/* .navbar-rigth .link-mi-cuenta span:hover {
    font-weight: 600;
} */
.navbar-rigth .link-mi-cuenta i {
    font-size: 25px;
    padding: 15px 10px 10px 10px;
}
.navbar-rigth .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-bottom: 20px;
}

/* BUSCADOR */
.container-buscador-to {
    width: 50%;
}
.container-buscador-to .icon-search {
    position: absolute !important;
    left: 0 !important;
    width: 55px;
    height: 46px;
    top: 0;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container-buscador-to .icon-search em {
    font-size: 25px;
    color: #282828;
    border-right: 2px solid #aaa8a8;
    padding-right: 10px;
    padding-left: 15px;
}
.container-buscador-to .icon-search img {
    width: 50px;
    border-right: 2px solid #aaa8a8;
    padding-right: 10px;
    padding-left: 15px;
    cursor: pointer;
}
.buscador-to {
    height: 46px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #aaa8a8 !important;
    border-radius: 17px !important;
    font-family: "Mukta";
    font-weight: 400;
    font-size: 18px !important;
    padding-left: 61px !important;
}
.buscador-to::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    letter-spacing: 0px;
    color: #aaa8a8 !important;
    font-family: "Mukta";
    font-weight: 400;
    font-size: 18px !important;
}
.buscador-to:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    letter-spacing: 0px;
    color: #aaa8a8 !important;
    font-family: "Mukta";
    font-weight: 400;
    font-size: 18px !important;
    opacity: 1;
}
.buscador-to::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    letter-spacing: 0px;
    color: #aaa8a8 !important;
    font-family: "Mukta";
    font-weight: 400;
    font-size: 18px !important;
    opacity: 1;
}
.buscador-to:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    letter-spacing: 0px;
    color: #aaa8a8 !important;
    font-family: "Mukta";
    font-weight: 400;
    font-size: 18px !important;
}
.buscador-to::-ms-input-placeholder {
    /* Microsoft Edge */
    letter-spacing: 0px;
    color: #aaa8a8 !important;
    font-family: "Mukta";
    font-weight: 400;
    font-size: 18px !important;
}

.buscador-to::placeholder {
    /* Most modern browsers support this now. */
    letter-spacing: 0px;
    color: #aaa8a8 !important;
    font-family: "Mukta";
    font-weight: 400;
    font-size: 18px !important;
}

.sticky {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 0;
    left: 0;
    width: 100%;
}
@media only screen and (max-width: 1024px) {
    .container-navbar {
        margin-left: 2% !important;
        margin-right: 2% !important;
    }
    .container-buscador-to {
        width: 43%;
        margin-right: 15px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .container-buscador-to {
        width: 52%;
    }
    .navbar-rigth span {
        display: none;
    }
    .container-navbar {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
    .container-brand {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .container-brand #CARRITO-MOVIL {
        display: block;
    }
    .container-brand #CARRITO-MOVIL span {
        display: none;
    }
    .navbar-rigth {
        font-size: 10px !important;
    }
    .navbar-rigth .link-tiendas {
        margin-right: 15px;
    }
    .navbar-rigth {
        display: none;
    }
    /* BUSCADOR */
    .container-buscador-to {
        width: 85%;
        margin: 0 0 5px 0;
    }
    .buscador-to {
        height: 36px !important;
        font-size: 15px !important;
        padding-left: 51px !important;
    }
    .buscador-to::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        font-size: 14px !important;
    }
    .buscador-to:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        font-size: 14px !important;
    }
    .buscador-to::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        font-size: 14px !important;
    }
    .buscador-to:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 14px !important;
    }
    .buscador-to::-ms-input-placeholder {
        /* Microsoft Edge */
        font-size: 14px !important;
    }

    .buscador-to::placeholder {
        /* Most modern browsers support this now. */
        font-size: 14px !important;
    }
    .container-buscador-to .icon-search {
        width: 45px;
        height: 36px;
    }
    .container-buscador-to .icon-search em {
        font-size: 20px;
        color: #282828;
    }
}
