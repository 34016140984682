.txt-contactenos {
    padding-left: 1rem;
}

@media only screen and (max-width: 425px) {
    .txt-contactenos {
        padding: 0 !important;
    }
    .txt-contactenos label {
        text-align: center;
    }
}
