@import "../../../../style/configs/color.css";
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
html {
    background-color: #fff;
}

.container-home-info {
    background-color: #bfd8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
}
.container-logo-text {
    width: 55%;
}
.txt-home-info {
    font-weight: 900;
    text-align: center;
    max-width: 100%;
}

.container-img-home-info {
    margin-top: 25px;
    margin-bottom: 15px;
}
.container-home-info .container-img-home-info img {
    height: 300px;
}

.container-home-body {
    padding: 30px 0;
    font-size: 14px;
    margin-left: 8%;
    margin-right: 8%;
}

.container-home-body .form-container {
    padding-left: 15px;
    padding-right: 15px;
    border: 0 solid #dcdcdc;
    border-radius: 0;
    box-shadow: 0 0 0 #282828;
    background-color: #f5f5f5;
}

.container-home-body .titulo-listado-tiendas {
    font-weight: 800;
    color: #282828;
    margin-left: 15px;
    margin-bottom: 30px;
    font-family: "Mukta", sans-serif;
}

.card-home {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 20px 15px;
    overflow: hidden;
}

/* .img-card-home {
    min-height: 200px;
    max-height: 200px;
} */

.info-card-home {
    width: 100%;
    font-family: "Mukta", sans-serif;
}

.card-home-title {
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    max-width: 400px;
    font-size: 14px;
    font-weight: 800;
    height: calc(14px * 1.4 * 2);
    /* Fallback for non-webkit */
    margin: 0 auto;
    line-height: 1.4;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    min-height: 40px;
    margin-top: 15px;
    font-family: "Mukta", sans-serif;
}

@media only screen and (max-width: 1440px) {
    .txt-home-info {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 1024px) {
    .container-home-info {
        padding-left: 10%;
        padding-right: 10%;
    }
    .container-home-body {
        margin-left: 2%;
        margin-right: 2%;
    }
    .txt-home-info {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 768px) {
    .container-home-info {
        padding-left: 15px;
        padding-right: 15px;
    }
    .container-home-body {
        margin-right: 0px;
        margin-left: 0px;
    }

    .container-home-info {
        padding-top: 15px;
    }
}

@media only screen and (max-width: 425px) {
    .container-home-info .container-img-home-info img {
        height: 250px;
    }
}
