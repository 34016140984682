@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

html, body {
    background-color: #F5F5F5 !important;
}
/* BREADCRUMB */
.breadcrumb-pago {
    margin-bottom: 80px !important;
    padding: 15px 0;
}
#PASOS-PAGO .container-breadcrumb{
    background-color: #8BB8FF;
    display: flex;
    align-items: center;
    width: 100%;
}

#PASOS-PAGO .container-breadcrumb .breadcrumb {
    padding: 0px 10px !important;
    margin-left: 15% !important;
    margin-right: 15% !important;
    background-color: transparent;

}
#PASOS-PAGO .container-breadcrumb .breadcrumb .txt-paso {
    color: #282828;
    font-family   : 'Mukta';
    text-transform: uppercase;
    font-size: 24px;
}
#PASOS-PAGO .container-breadcrumb .breadcrumb .txt-titulo-paso {
    color: #282828;
    font-family   : 'Mukta';
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
}

#PASOS-PAGO .container-breadcrumb .breadcrumb .txt-descripcion-paso {
    color: #282828;
    font-family   : 'Mukta';
    font-size: 17px;
    line-height: 17px;
}

.container-tienda-pago {
    margin-left: 15%;
    margin-right: 15%;
    overflow: hidden;
    width: 100%;
    padding: 0 10px;
}

#FORMS-PAGO .form-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
}

.pago-titulo {
    color: #282828;
    font-family   : 'Mukta';
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
}

/* TABLA */
table.table-carrito {
    font-family   : 'Mukta';
}
table.table-carrito thead tr {
    width: 100%;
}
table.table-carrito thead th {
    font-weight: 800;
    background-color: #DCDCDC;
    border: 0;
    text-transform: uppercase;
    padding:15px;
    width: inherit;
}
table.table-carrito tbody tr {
    border-bottom: 2px solid #DCDCDC;
}
table.table-carrito td {
    border: 0;
    position: relative !important;
    padding: 20px 15px !important;
}
table.table-carrito td .icono-eliminar {
    color: #FF006E;
    font-size: 17px;
    cursor: pointer;
    font-weight: 200;
    border: 1px solid #B7B7B7;
    border-radius: 50%;
    height: 25px !important;
    width: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

table.table-carrito td .icono-eliminar:hover {
    background-color: #FF006E;
    color: #FFF;
}

table.table-carrito td .img-producto-carrito {
    width: 62px;
    height: 56px;
    border: 1px solid #B7B7B7;
    border-radius: 8px;
    padding: 15px;
}
table.table-carrito td .nombre-producto {
    margin-left: 1rem;
    font-size: 15px;
}
table.table-carrito td .precio-producto-carrito {
    font-size: 17px;
    font-weight: 700;
    color: #282828;
}
table.table-carrito td.control-cantidad-carrito div {
    width: auto !important;
    height: auto !important;
}

table.table-carrito td.control-cantidad-carrito .btn-azul-venta {
    width: 34px;
    height: 42px;
    background-color: #3A86FF;
    border-radius: 5px !important;
}
table.table-carrito td.control-cantidad-carrito .btn-azul-venta:hover {
    opacity: 0.8;
}
table.table-carrito td.control-cantidad-carrito input {
    font-family   : 'Mukta';
    font-weight: 500;
    font-size: 15px;
    width: 49px;
    height: 43px !important;
    margin: 0 8px;
    border-radius: 5px !important;
    border: 1px solid #B7B7B7 !important;
}

.txt-total-carrito {
    background-color: #DCDCDC;
    font-family   : 'Mukta';
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    padding: 0 15px;
    min-height: 54px;
    line-height: 54px;
}
.total-carrito {
    font-family   : 'Mukta';
    font-weight: 700;
    padding: 0 15px;
    color: #3A86FF;
    width: 100%;
    font-size: 20px;
}

.btn-comprar-carrito {
    margin-top: 45px;
    margin-bottom: 20px;
    cursor         : pointer;
    width          : 100%;
    height         : 45px;
    border         : 3px solid #FFBE0B;
    background     : #FFBE0B;
    color          : #282828;
    border-radius  : 10px !important;
    display        : flex;
    align-items    : center;
    justify-content: center;
    font-family   : 'Mukta';
    font-weight   : 700;
    font-size     : 20px;
    letter-spacing: 0px;
}
.btn-comprar-carrito:hover {
    background: #ffbe0bce 50% 0% no-repeat padding-box;
    color     : #282828;
    border    : 0;
}

.btn-volver-carrito {
    cursor         : pointer;
    width          : 100%;
    height         : 45px;
    border: 2px solid #3A86FF;
    color: #3A86FF !important;
    border-radius  : 10px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    font-family   : 'Mukta';
    font-weight   : 700;
    font-size     : 20px;
    letter-spacing: 0px;
    background-color: #FFFFFF;
}
.btn-volver-carrito:hover {
    text-decoration: none !important;
    background-color: #3A86FF;
    color: #FFFFFF !important;
}
.btn-secondary.btn-pago {
    border: 0;
    box-shadow: 0 0 0 #000 !important;
}

.compraFinalizada {
    background-color: white;
    width           : 50%;
    margin          : auto;
    margin-top      : 70px;
    margin-bottom   : 70px;
    padding         : 130px 100px;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    text-align      : center;
    border-radius   : 15px;
    font-family   : 'Mukta';
    box-shadow: 0px 3px 6px #00000029;
}
.compraFinalizada .img-exito {
    width: 182px;
}
.compraFinalizada .titulo-exito {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 50px;
}
.compraFinalizada .txt-exito {
    font-size: 20px;
}
.compraFinalizada .btn-comprar-carrito {
    width: 245px;
    text-decoration: none !important;
    height: 43px;
    margin-top: 50px !important;
}

.RSPBprogressBar {
    flex-direction: row;
}

.icono-eliminar {
    position: relative;
}

/* ///////////////////////////////////////////// */
/* PASO 2 */
/* ///////////////////////////////////////////// */
.container-form {
    font-family: 'Mukta';
    margin: 50px 15% 0 15%;
}
.container-form .grid-titulo {
    font-family: 'Mukta';
    font-size: 25px;
    font-weight: 7000;
    letter-spacing: 0px;
    color: #282828;
    border-bottom: 3px solid #DCDCDC;
}
.container-form .grid-titulo-sm {
    color: #AAA8A8;
    font-size: 20px;
}

.container-form .form-group label {
    font-weight: bold;
    font-size: 18px;
}
.container-form .form-group .form-control {
    height: 43px !important;
    border: 2px solid #AAA8A8;
    font-size: 18px;
    border-radius: 10px;
}
.container-form .form-group .form-control:focus {
    border: 2px solid #FFBE0B;
}

.container-form .form-group .form-control:invalid, .container-form .form-group .form-control.is-invalid {
    border-color: #dc3545 !important;
}

.container-form .form-group .form-control .Select-value {
    padding-top: 5px;
}
.container-form .form-group .form-group .Select-clear-zone, .container-form .form-group .form-group .Select-arrow-zone {
    padding-top: 7px !important;
}
.container-form .form-group .form-group .Select-clear-zone .Select-clear {
    font-size: 33px;
}

.container-form .title-item {
    color: #282828;
    font-size: 18px;
    font-weight: 700;
}
.container-form .descripcion-item {
    color: #3A86FF;
    font-weight: 400;
    font-size: 18px;
}
.container-form .c-radio span {
    border: 1px solid #707070;
}
.container-form .c-radio.c-radio-nofont input[type=radio]:checked+span:before {
    background-color: #FFBE0B;
}
.container-form .c-radio.c-radio-nofont input[type=radio]:checked+label:before {
    background-color: #FFBE0B;
}
.container-form .btn-volver-carrito {
    width: 230px;
    font-size: 18px;
    height: 40px;
}
.container-form .btn-comprar-carrito {
    width: 246px;
    height: 43px;
}
.container-form .btn-secondary {
    width: 246px;
    height: 43px;
    background-color: #282828;
    border-radius  : 10px;
    font-family   : 'Mukta';
    font-weight   : 700;
    font-size     : 20px;
}
.container-form .container-datos-banco label {
    color: #282828;
    font-size: 18px;
}
.container-form .container-datos-banco div {
    color: #282828;
    font-size: 18px;
    font-weight: 400;
}
.container-info-radio {
    width: 85%;
}
/* ///////////////////////////////////////////// */

/* ///////////////////////////////////////////// */
/* PASO 2 */
/* ///////////////////////////////////////////// */
.container-form .form-group .label-view {
    color: #030101;
    font-size: 15px;
}
.container-form .form-group .input-view {
    font-size: 15px;
    border: 0;
    font-weight: 400;
}
.container-form .table-detalle-pedido thead tr {
    border: 1px solid #A3A3A3;
}
.container-form .table-detalle-pedido thead th:first-child {
    width: 70%;
}
.container-form .table-detalle-pedido thead th:last-child {
    width: 30%;
}
.container-form .table-detalle-pedido thead th {
    background-color: #DCDCDC;
    padding-left: 30px;
    font-size: 20px;
    border: 0 solid #DCDCDC;
}
.container-form .table-detalle-pedido tbody tr {
    border: 1px solid #A3A3A3;
    color: #707070;
}
.container-form .table-detalle-pedido td {
    border: 0;
    padding-left: 30px;
    font-size: 18px;
}
.container-form .table-detalle-pedido td:first-child {
    font-weight: 400;
}
.container-form .table-detalle-pedido td:last-child {
    font-weight: 700;
}
.container-form .table-detalle-pedido .row-total {
    background-color: #3A86FF;
    color: #FFFFFF;
}
.container-form .table-detalle-pedido .row-total td{
    font-weight: 700 !important;
}

/* ///////////////////////////////////////////// */

@media only screen and (max-width: 1440px) {
    .compraFinalizada {
        width: 70%;
        padding: 100px 100px;
    }
}

@media only screen and (max-width: 1024px) {
    #PASOS-PAGO .container-breadcrumb .breadcrumb {
        padding: 0 10px;
        margin-left: 8% !important;
        margin-right: 8% !important;
    }
    .form-container {
        width: 84% !important;
    }

    .container-tienda-pago {
        margin-left: 8% !important;
        margin-right: 8% !important;
    }
    .txt-total-carrito {
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 23px;
        font-size: 20px;
    }

    .container-form {
        margin-left: 8%;
        margin-right: 8%;
    }
    .compraFinalizada .titulo-exito {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .form-container {
        width: 90% !important;
    }
    .container-tienda-pago {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }
    .compraFinalizada {
        width  : 90%;
        padding: 20px 15px;
    }

    .RSPBprogressBar {
        margin        : 15px 0;
        width         : 80% !important;
        height        : 160px !important;
    }

    .RSPBprogressBar .RSPBstep {
        position       : relative !important;
        transform      : translateX(0%) !important;
        left           : auto !important;
        width          : 100%;
        display        : flex;
        align-items    : center;
        justify-content: start;
    }
    .btn-comprar-carrito {
        margin: 8px 16px;
        width: 246px;
    }
    .btn-volver-carrito {
        margin: 8px 16px;
        width: 246px;
    }
    .container-total-btn-carrito {
        margin: 0 !important;
        padding: 0 !important;
    }
    .total-carrito {
        font-size: 30px;
    }
    .container-form {
        margin-left: 15px;
        margin-right: 15px;
    }
    .container-form .grid-titulo {
        font-family: 'Mukta';
        font-size: 20px;
    }
    .container-form .grid-titulo-sm {
        font-size: 15px;
    }
    .container-form .form-group label {
        font-size: 15px;
    }
    .container-form .form-group .form-control {
        font-size: 15px;
    }
    .container-form .title-item {
        font-size: 15px;
    }
    .container-form .descripcion-item {
        font-size: 15px;
    }
}

@media only screen and (max-width: 425px) {
    .compraFinalizada {
        width  : 90%;
        padding: 20px 15px;
    }
    .RSPBprogressBar {
        flex-direction: column;
    }

    .icono-eliminar {
        position: absolute;
        left: 10px;
        margin: 0;
    }
    .nombre-producto {
        margin: 0;
        text-align: center;
    }
    .btn-pago {
        height: 35px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* BREADCRUMB */
    .breadcrumb-pago {
        margin-bottom: 40px;
        padding: 10px 0 !important;
    }
    .container-breadcrumb .breadcrumb .txt-paso {
        font-size: 20px;
    }
    .container-breadcrumb .breadcrumb .txt-titulo-paso {
        font-size: 16px;
    }
    .container-breadcrumb .breadcrumb .txt-descripcion-paso {
        font-size: 13px;
    }

    /* TABLA */
    table.table-carrito thead th {
        width: 50%;
    }
    table.table-carrito td .img-producto-carrito {
        width: 52px;
        height: 46px;
        padding: 5px;
    }
    table.table-carrito td .nombre-producto {
        margin-left: 0.5rem;
        font-size: 13px;
    }
    table.table-carrito td.control-cantidad-carrito .btn-azul-venta {
        width: 24px;
        height: 32px;
    }
    table.table-carrito td.control-cantidad-carrito input {
        font-size: 12px;
        width: 39px;
        height: 32px !important;
        margin: 0 2px;
    }
    table.table-carrito td .icono-eliminar {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .container-form {
        margin-left: 0;
        margin-right: 0;
    }
    .container-form .grid-titulo {
        font-family: 'Mukta';
        font-size: 20px;
    }
    /* .container-form .col-12 {
        padding-left: 0;
        padding-right: 0;
    } */
    .container-form .btn-comprar-carrito {
        margin: 0 !important;
        width: 100%;
    }
    .container-form .btn-secondary {
        width: 100%;
        margin: 0 0 20px 0 !important;
    }
    .btn-comprar-carrito {
        margin: 0 0 20px 0;
        width: 100%;
    }
    .btn-volver-carrito {
        margin: 0 !important;
        width: 100%;
    }

    /* PASO 3 */
    .container-form .table-detalle-pedido thead th:first-child {
        width: 50%;
    }
    .container-form .table-detalle-pedido thead th:last-child {
        width: 50%;
    }
    .container-form .table-detalle-pedido thead th {
        font-size: 18px;
    }
    .container-form .table-detalle-pedido td {
        padding-left: 15px;
        font-size: 15px;
    }
    .compraFinalizada .img-exito {
        width: 100px;
    }
    .compraFinalizada .titulo-exito {
        font-size: 20px;
    }
    .compraFinalizada .txt-exito {
        font-size: 17px;
    }

    .compraFinalizada .btn-comprar-carrito {
        width: 100%;
    }
}
