@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
/* CARD-LOGIN */
.card-login {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 15px !important;
    font-family: "Mukta", sans-serif !important;
    width: 45% !important;
}
.card-login .card-header {
    background-color: transparent;
    border: 0 solid;
    font-weight: bold;
    font-size: 25px;
    padding: 25px 10% 0 10%;
}
.card-login .card-header .card-title {
    border-bottom: 4px solid #dcdcdc;
}
.card-login .card-header .icon-login {
    height: 55px;
    width: 50px;
    margin-bottom: 15px;
}
.card-login .card-body {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5px;
}

.card-login .card-body .txt-login {
    font-size: 20px;
    font-weight: bold;
}

.login-overlay .panel-body {
    min-height: 244px;
}
.has-feedback {
    position: relative;
}
.form-control-feedback {
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.bienvenida {
    color: white;
    font-weight: bold;
}
.buttons-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-bg {
    background-color: #f5f5f5;
    height: 100%;
    padding: 35px 0;
    color: #282828 !important;
}

.btn-facebook {
    background-color: #3a589e;
    height: 35px;
    color: #fff;
    border: 0;
    font-family: "Mukta", sans-serif !important;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px 15px;
    margin-right: 10px;
}
.btn-facebook:hover {
    opacity: 0.9;
}
.btn-facebook i {
    margin-right: 0.5em;
}

.btn-google {
    background-color: #da4e30 !important;
    height: 35px;
    color: #fff !important;
    border: 0 !important;
    font-family: "Mukta", sans-serif !important;
    font-size: 18px !important;
    font-weight: bold !important;
    border-radius: 5px !important;
    padding: 5px 15px !important;
    outline: 0 !important;
    margin-left: 10px;
}
.btn-google div {
    display: none !important;
    background-color: #da4e30 !important;
}
.btn-google i {
    margin-right: 0.5em;
}

.container-btn-redes {
    width: 100%;
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.card-login label {
    font-size: 18px;
    color: #282828;
}
.card-login input {
    background-color: #fff;
    border: 2px solid #aaa8a8;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
}
.card-login input:focus {
    border: 2px solid #ffbe0b;
}
.card-login .txt-info {
    font-size: 16px;
    font-weight: normal;
}
.card-login .content-btn-registro {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 230px;
}

.btn-login {
    background-color: #ffbe0b !important;
    border-radius: 5px !important;
    padding: 5px 15px !important;
    height: 35px;
    width: 50%;
    color: #282828 !important;
    font-size: 16px !important;
}
.btn-login:hover {
    opacity: 0.9;
}

.txt-olvide {
    color: #3a86ff;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-align: center !important;
    margin: 15px 0;
}

/* MEDIUM  DESKTOP */
@media only screen and (max-width: 1440px) and (min-width: 1025px) {
    .card-login {
        width: 50% !important;
    }
}

/* SMALL  DESKTOP */
@media only screen and (max-width: 1024px) and (min-width: 992px) {
    .card-login {
        width: 75% !important;
    }
}

/* TABLET */
@media only screen and (max-width: 991px) and (min-width: 768px) {
    .card-login {
        width: 100% !important;
    }
}

/* LARGE MOBILE */
@media only screen and (max-width: 767px) and (min-width: 425px) {
    .card-login {
        width: 100% !important;
    }
    .container-btn-redes {
        flex-direction: column;
    }
    .container-btn-redes .btn-facebook,
    .container-btn-redes .btn-google {
        margin: 7.5px 0;
        /* width: 85%; */
    }
}
/* MEDIUM AND SMALL MOBILE */
@media only screen and (max-width: 424px) and (min-width: 0px) {
    .card-login {
        width: 100% !important;
    }
    .container-btn-redes {
        flex-direction: column;
    }
    .container-btn-redes .btn-facebook,
    .container-btn-redes .btn-google {
        margin: 7.5px 0;
        /* width: 85%; */
    }
}
