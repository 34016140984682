@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
html {
    background-color: #FFF !important;
}
#COMPLETAR-PERFIL {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-left     : 8%;
    margin-right    : 8%;
    height: 100%;
    font-family   : 'Mukta', sans-serif !important;
}
#COMPLETAR-PERFIL .card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
}
#COMPLETAR-PERFIL .card .card-header {
    background-color: transparent;
    border: 0 solid;
    font-weight: bold;
    font-size: 25px;
    padding: 25px 10% 0 10%;
}
#COMPLETAR-PERFIL .card .card-header .card-title {
    border-bottom: 4px solid #DCDCDC;
}
#COMPLETAR-PERFIL .card .card-header .icon {
    height: 55px;
    width: 50px;
    margin-bottom: 15px;
}
#COMPLETAR-PERFIL .card .card-body {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5px;
}
#COMPLETAR-PERFIL .card .card-body label {
    font-size: 18px;
    color: #282828;
}

#COMPLETAR-PERFIL .card .card-body input {
    background-color: #FFF;
    border: 2px solid #AAA8A8;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
}
#COMPLETAR-PERFIL .card .card-body input:focus {
    border: 2px solid #FFBE0B;
}

#COMPLETAR-PERFIL .card.card-exito {
    padding-top: 50px;
    padding-bottom: 50px;
}
#COMPLETAR-PERFIL .card.card-exito .card-body {
    padding-top: 0;
    padding-bottom: 0;
}
#COMPLETAR-PERFIL .card .icon-exito {
    width: 120px;
    height: 120px;
}
#COMPLETAR-PERFIL .card .txt-exito {
    letter-spacing: 0px;
    color: #282828;
    font-weight: bold;
    margin: 50px 0;
}
#COMPLETAR-PERFIL .btn-txt {
    background-color: transparent;
    color: #3A86FF;
    font-size: 16px !important;
    margin-left: 15px;
}
@media only screen and (max-width: 1024px) {
    .#COMPLETAR-PERFIL {
        margin-left : 2%;
        margin-right: 2%;
    }
}

@media only screen and (max-width: 768px) {
    .#COMPLETAR-PERFIL {
        margin-right: 0px;
        margin-left : 0px;
    }
}