@import './configs/color.css';
/* ----------- UTILS ---------------- */

/* Margin */

.mt0 {
    margin-top: 0;
}

.mt {
    margin-top: 0.5rem;
}

.mt1 {
    margin-top: 1.5rem;
}

.mt2 {
    margin-top: 2rem;
}

.mb0 {
    margin-bottom: 0;
}
.mb05 {
    margin-bottom: 0.5em;
}
.mb {
    margin-bottom: 0.6rem;
}

.mb1 {
    margin-bottom: 1.5rem;
}

.mb2 {
    margin-bottom: 2rem;
}

.mb3 {
    margin-bottom: 3rem;
}

.mr0 {
    margin-right: 0;
}

.mr {
    margin-right: 1rem;
}

.mr1 {
    margin-right: 1.5rem;
}

.mr2 {
    margin-right: 2rem;
}

.ml0 {
    margin-left: 0;
}

.ml {
    margin-left: 1rem;
}

.ml1 {
    margin-left: 1.5rem;
}

.ml2 {
    margin-left: 2rem;
}

/* Padding */

.p15 {
    padding: 15px;
}
.pt-05{
    padding-top: 0.5rem !important;
}
.pt-15 {
    padding-top: 15px;
}
.pl-15 {
    padding-left: 15px;
}
.pr-15 {
    padding-right: 15px
}
.pr-0 {
    padding-right: 0;
}
.pb-15 {
    padding-bottom: 15px;
}
.pb-05 {
    padding-bottom: 0.5em !important;
}
.pf-1{
    padding-right: 1rem !important;
}

/* Color */

.naranja {
    color: $naranja-oscuro;
}

/* Flex */
.flex01 {
    flex:0.1 !important;
}
.flex02 {
    flex:0.2 !important;
}
.flex03 {
    flex:0.3 !important;
}
.flex04 {
    flex:0.4 !important;
}
.flex05 {
    flex:0.5 !important;
}
.flex06 {
    flex:0.6 !important;
}
.flex07 {
    flex:0.7 !important;
}
.flex08 {
    flex:0.8 !important;
}
.flex09 {
    flex:0.9 !important;
}

.flex1 {
    flex:1 !important;
}
.flex2 {
    flex:2 !important;
}
.flex3 {
    flex:3 !important;
}
.flex4 {
    flex:4 !important;
}
.flex5 {
    flex:5 !important;
}
.flex6 {
    flex:6 !important;
}
.flex7 {
    flex:7 !important;
}
.flex8 {
    flex:8 !important;
}
.flex9 {
    flex:9 !important;
}
.flex10 {
    flex:10 !important;
}


/* Bordes */
.border-b-3 {
    border-bottom: 3px solid #C4C4C4;
}

.fnt-10 {
    font-size: 10px !important;
}
.fnt-11 {
    font-size: 11px !important;
}
.fnt-12 {
    font-size: 12px !important;
}
.fnt-14 {
    font-size: 14px !important;
}
.fnt-16 {
    font-size: 16px !important;
}
.fnt-18 {
    font-size: 18px !important;
}
.fnt-08-rem {
    font-size: 0.8rem;
}
.fnt-1-5-rem {
    font-size: 1.5rem;
}

@media only screen and (max-width: 768px) {
    .flex-xs-column {
        flex-direction: column !important;
    }
    .flex-xs-row {
        flex-direction: row !important;
    }
    .d-xs-none {
        display: none !important;
    }
    .mb-sm-5 {
        margin-bottom: 5px;
    }
    .mb-sm-5 {
        margin-bottom: 5px;
    }
    .mb-sm-10 {
        margin-bottom: 10px;
    }
    .mt-sm-5 {
        margin-top: 5px;
    }
    .mt-sm-10 {
        margin-top: 10px;
    }
}
@media only screen and (max-width: 768px) {
    .fnt-sm-10{
        font-size: 10px;
    }
    .fnt-sm-12{
        font-size: 12px;
    }
    .fnt-sm-14{
        font-size: 14px;
    }
    .fnt-sm-16{
        font-size: 16px;
    }
    .fnt-sm-18{
        font-size: 18px;
    }
    .fnt-sm-20{
        font-size: 20px;
    }
    .fnt-sm-22{
        font-size: 22px;
    }
    .fnt-sm-24{
        font-size: 24px;
    }
    .p-sm-0{
        padding: 0 !important;
    }
    .pt-sm-1{
        padding-top: 1rem !important;
    }
    .pb-sm-1{
        padding-bottom: 1rem !important;
    }
    .pl-sm-1{
        padding-left: 1rem !important;
    }
    .pf-sm-1{
        padding-right: 1rem !important;
    }
}


@media only screen and (min-width: 768px) {
    .fnt-lg-10{
        font-size: 10px;
    }
    .fnt-lg-12{
        font-size: 12px;
    }
    .fnt-lg-14{
        font-size: 14px;
    }
    .fnt-lg-16{
        font-size: 16px;
    }
    .fnt-lg-18{
        font-size: 18px;
    }
    .fnt-lg-20{
        font-size: 20px;
    }
    .fnt-lg-22{
        font-size: 22px;
    }
    .fnt-lg-24{
        font-size: 24px;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

