.container-shopping-cart {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    width          : 30px;
    height         : 50px;
    cursor         : pointer;
}

.container-quantity-items {
    position        : absolute;
    display         : flex;
    align-items     : center;
    justify-content : center;
    width           : 20px;
    height          : 20px;
    background-color: #FF006E;
    border-radius   : 50%;
    padding         : 11px;
    color           : white;
    top             : 2px;
    right           : -10px;
    font-weight     : 700;
}

.txt-carrito {
    font-weight: normal;
    letter-spacing: 0px;
    color: #484848;
    text-transform: uppercase;
    cursor: pointer;
}

/* .txt-carrito:hover {
    font-weight: 600;
} */